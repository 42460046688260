import { Component, OnInit } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { LanguageLink } from 'src/app/interfaces/language.interface';
import { LANGUAGES } from '@const/language-list.const';

@Component({
  selector: 'app-dropdown',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule
  ],
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss',
})
export class DropdownComponent implements OnInit {
  public selectedControl: FormControl = new FormControl(null);
  public languages: LanguageLink[] = LANGUAGES;
  public selectedLang: LanguageLink | undefined;

  constructor() {}

  ngOnInit(): void {
    this.getLanguage();
  }

  public getLanguage(): void {
    const langKey: string | null = localStorage.getItem('lang');
    switch (langKey) {
      case 'Ru':
        this.selectedLang = this.languages.find((lang) => lang.name === 'РУС')!;
        break;
      case 'Pl':
        this.selectedLang = this.languages.find((lang) => lang.name === 'PLD')!;
        break;
      default:
        this.selectedLang = this.languages.find((lang) => lang.name === 'ENG')!;
    }

    if (this.selectedLang) {
      this.selectedControl.setValue(this.selectedLang);
    }
  }

  public onLanguageChange(lang: LanguageLink) {
    this.selectedLang = lang;
    window.location.href = lang.url;
  }
}
