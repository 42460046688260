import { APP_INITIALIZER, ApplicationConfig } from '@angular/core';
import {
  InMemoryScrollingFeature,
  InMemoryScrollingOptions,
  PreloadAllModules,
  RouterModule,
  provideRouter,
  withInMemoryScrolling,
  withPreloading,
} from '@angular/router';
import { routes } from './app.routes';
import { provideHttpClient } from '@angular/common/http';
import { IconsModule } from './modules/icons.module';
import { provideFirebaseApp } from '@angular/fire/app';
import { initializeApp } from 'firebase/app';
import { environment } from '../environments/environment';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { AppLoaderService } from './services/app-loader.service';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
};
const inMemoryScrollingFeature: InMemoryScrollingFeature =
  withInMemoryScrolling(scrollConfig);

function initializeApplication(appLoaderService: AppLoaderService) {
  return () => appLoaderService.initializeApplication();
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(),
    IconsModule,
    RouterModule,
    provideAnimationsAsync(),
    provideRouter(routes, inMemoryScrollingFeature, withPreloading(PreloadAllModules)),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApplication,
      deps: [AppLoaderService],
      multi: true,
    }, provideAnimationsAsync(),
  ],
};
