import { DataService } from 'src/app/services/data.service';
import { Injectable } from '@angular/core';
import { PersonService } from './person.service';
import { ProjectsService } from './projects.service';
import { SettingsService } from './settings.service';
import { ReviewsService } from './reviews.service';
import { VacanciesService } from './vacancies.service';
import { combineLatest } from 'rxjs';
import { ArgumentsService } from './arguments.service';

@Injectable({
  providedIn: 'root',
})
export class AppLoaderService {
  constructor(
    private personService: PersonService,
    private projectsService: ProjectsService,
    private settingsService: SettingsService,
    private reviewsService: ReviewsService,
    private vacanciesService: VacanciesService,
    private argumentsService: ArgumentsService,
    private dataService: DataService
  ) {}

  public initializeApplication() {
    this.initializeLanguage();
    this.getDataFromDB();
  }

  public initializeLanguage() {
    const hostname: string = document.location.hostname;
    let language: string;

    if (hostname.includes('.by')) {
      language = 'Ru';
    } else if (hostname.includes('.pl')) {
      language = 'Pl';
    } else {
      language = 'En';
    }
    localStorage.setItem('lang', language);
  }

  private getDataFromDB() {
    combineLatest(
      this.personService.getAllPersons(),
      this.projectsService!.getAllProjects(),
      this.settingsService.getSettings(),
      this.reviewsService.getAllReviews(),
      this.vacanciesService.getAllVacancies(),
      this.argumentsService.getAllTechnologies(),
      this.argumentsService.getAllServices(),
      this.argumentsService.getAllIndustries()
    ).subscribe(([
        persons,
        projects,
        settings,
        reviews,
        vacancies,
        technologies,
        services,
        industries,
      ]) => {
        this.dataService.persons.next(persons)
        this.dataService.projects.next(projects)
        this.dataService.settings.next(settings)
        this.dataService.reviews.next(reviews)
        this.dataService.vacancies.next(vacancies)
        this.dataService.technologies.next(technologies)
        this.dataService.services.next(services)
        this.dataService.industries.next(industries)
      });
  }
}
