import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsResponse } from '@interfaces/response.interface';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private http: HttpClient) {}

  public getSettings() {
    const lang = localStorage.getItem('lang');

    const body = `query Query {
      settings {
        uuid
        key
        settings
      }
    }`;

    return this.http
      .post<SettingsResponse>(environment.pixelUrl, {
        query: JSON.parse(JSON.stringify(body)),
        variables: { language: [lang] },
        operationName: 'Query',
      })
      .pipe(map((res) => res.data.settings));
  }
}
