import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadComponent: () =>
      import('@pages/home/home.component').then((c) => c.HomeComponent),
  },
  {
    path: 'company',
    loadChildren: () =>
      import('@pages/company/company.routes').then((c) => c.CompanyRoutes),
  },
  {
    path: 'contact-us',
    loadComponent: () =>
      import('@pages/contact-us-page/contact-us-page.component').then(
        (c) => c.ContactUsPageComponent
      ),
  },
  {
    path: 'industries',
    loadChildren: () =>
      import('@pages/industries/industries.routes').then(
        (c) => c.IndustriesRoutes
      ),
  },
  {
    path: 'technologies',
    loadChildren: () =>
      import('@pages/technologies/technologies.routes').then(
        (c) => c.TechnologiesRoutes
      ),
  },
  {
    path: 'services',
    loadChildren: () =>
      import('@pages/services/services.routes').then((c) => c.servicesRout),
  },
  {
    path: 'projects',
    loadChildren: () =>
      import('@pages/projects-page/projects.routes').then(
        (c) => c.projectsRoute
      ),
  },
  {
    path: 'privacy-policy',
    loadComponent: () =>
      import('@pages/privacy-policy/privacy-policy.component').then(
        (c) => c.PrivacyPolicyComponent
      ),
  },
  {
    path: '**',
    redirectTo: 'home',
  }
];
