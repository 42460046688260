import { PersonService } from 'src/app/services/person.service';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from '@components/footer/footer.component';
import { HomeComponent } from '@pages/home/home.component';
import { MatIconModule } from '@angular/material/icon';
import { ContactUsComponent } from '@components/contact-us/contact-us.component';
import { ClientsSpeakComponent } from '@components/clients-speak/clients-speak.component';
import { ContactUsPageComponent } from '@pages/contact-us-page/contact-us-page.component';
import { OurContactsComponent } from '@components/our-contacts/our-contacts.component';
import { CompanyComponent } from '@pages/company/company.component';
import { IndustriesComponent } from '@pages/industries/industries.component';
import { IconsModule } from './modules/icons.module';
import { HeaderComponent } from '@components/header/header.component';
import { ServicesComponent } from '@pages/services/services.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    ContactUsComponent,
    ClientsSpeakComponent,
    ContactUsPageComponent,
    OurContactsComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    MatIconModule,
    IconsModule,
    CompanyComponent,
    IndustriesComponent,
    ServicesComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'elpixel-website';

  private localesList = [
    { code: 'en', label: 'ENG' },
    { code: 'ru', label: 'РУС' },
  ];
}
