import { Component } from '@angular/core';
import { LogoComponent } from '../logo/logo.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DropdownComponent } from "../dropdown/dropdown.component";

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [LogoComponent, CommonModule, RouterModule, DropdownComponent],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  public year!: Date;

  ngOnInit(): void {
    this.year = new Date();
  }
}
