import { animate, style, transition, trigger } from '@angular/animations';

export const enterLeaveAnimation = [
  trigger('animate', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('0.3s ease-out', style({ opacity: 1 })),
    ]),
    transition(':leave', [
      style({ opacity: 1 }),
      animate('0.3s ease-out', style({ opacity: 0 })),
    ]),
  ]),
  trigger('animate-close', [
    transition(':enter', [
      style({ opacity: 1 }),
      animate('0.3s ease-out', style({ opacity: 0 })),
    ]),
    transition(':leave', [
      style({ opacity: 0 }),
      animate('0.3s ease-out', style({ opacity: 1 })),
    ]),
  ]),
];
