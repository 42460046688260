<div class="header">
  <app-header></app-header>
</div>

<div class="main">
  <router-outlet></router-outlet>
</div>

<div class="footer">
  <app-footer></app-footer>
</div>
