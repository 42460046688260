<div class="footer">
  <div class="footer__navigation">
    <div class="technologies nav">
      <p class="l bold main footer-router" routerLink="/technologies" i18n="@@Technologies">
        Technologies
      </p>
      <p class="l bold second footer-router" routerLink="/technologies/Angular">
        Angular
      </p>
      <p class="l bold second footer-router" routerLink="/technologies/Vue">
        Vue
      </p>
      <p class="l bold second footer-router" routerLink="/technologies/React">
        React
      </p>
      <p class="l bold second footer-router" routerLink="/technologies/Java">
        Java
      </p>
      <p class="l bold second footer-router" routerLink="/technologies/NET">
        .NET
      </p>
      <p class="l bold second footer-router" routerLink="/technologies/Python">
        Python
      </p>
      <p class="l bold second footer-router" routerLink="/technologies/AWS">
        AWS
      </p>
      <p class="l bold second footer-router" routerLink="/technologies/Azure">
        Azure
      </p>
      <p class="l bold second footer-router" routerLink="/technologies/GCP">
        GCP
      </p>
      <p
        class="l bold second footer-router"
        routerLink="/technologies/Vision-models"
        i18n="@@Vision models"
      >Vision models</p>
      <p class="l bold main footer-router" routerLink="/technologies" i18n="@@+other">
        +other
      </p>
    </div>
    <div class="services nav">
      <p class="l bold main footer-router" routerLink="/services" i18n="@@Services">
        Services
      </p>
      <p
        class="l bold second footer-router"
        routerLink="/services/service-development"
        i18n="@@Software Development"
      >Software Development</p>
      <p
        class="l bold second footer-router"
        routerLink="/services/mobile-app-development"
        i18n="@@Mobile App Development"
      >Mobile App Development</p>
      <p class="l bold second footer-router" routerLink="/services/UX-UI" i18n="@@UX & UI Design">
        UX & UI Design
      </p>
      <p
        class="l bold second footer-router"
        routerLink="/services/business-analysis"
        i18n="@@Business Analysis"
      >
        Business Analysis
      </p>
      <p class="l bold main footer-router" routerLink="/services" i18n="@@+other">
        +other
      </p>
    </div>
    <div class="industries nav">
      <p class="l bold main footer-router" routerLink="/industries" i18n="@@Industries">
        Industries
      </p>
      <p
        class="l bold second footer-router"
        routerLink="/industries/finance"
        i18n="@@Finance & Banking"
      >Finance & Banking</p>
      <p
        class="l bold second footer-router"
        routerLink="/industries/healthcare"
        i18n="@@Healthcare & Life Sciences"
      >Healthcare & Life Sciences</p>
      <p
        class="l bold second footer-router"
        routerLink="/industries/ecommerce"
        i18n="@@eCommerce"
      >
        eCommerce
      </p>
      <p
        class="l bold second footer-router"
        routerLink="/industries/innovations"
        i18n="@@Hi-Tech & Innovations"
      >Hi-Tech & Innovations</p>
      <p
        class="l bold second footer-router"
        routerLink="/industries/education"
        i18n="@@eLearning & Education"
      >eLearning & Education</p>
      <p
        class="l bold second footer-router"
        routerLink="/industries/media"
        i18n="@@Media & Entertainment"
      >Media & Entertainment</p>
      <p
        class="l bold second footer-router"
        routerLink="/industries/logistics"
        i18n="@@Logistics & Transportation"
      >Logistics & Transportation</p>
      <p class="l bold main footer-router" routerLink="/industries" i18n="@@+other">
        +other
      </p>
    </div>
    <div class="company nav">
      <div class="company__links">
        <p class="l bold main footer-router" routerLink="/company" i18n="@@Company">Company</p>
        <p class="l bold main footer-router" routerLink="/company/career" i18n="@@Career">Career</p>
        <p class="l bold main footer-router" routerLink="/projects" i18n="@@Projects">Projects</p>
      </div>

      <app-dropdown></app-dropdown>

      <div class="info">
        <div class="info__logo">
          <app-logo [type]="'white'"></app-logo>
        </div>
        <div class="info__adress">
          <p class="l normal second" i18n="@@Pixel Soft LLC">Pixel Soft LLC</p>
          <p class="l normal second" i18n="@@Company registration number 264.110.1249679">
            Company registration number 264.110.1249679
          </p>
          <p class="l normal second">
            Czyzowka 14/1.3.2 30-526, Krakow, Poland
          </p>
        </div>
        <div class="info_mail">
          <a href="mailto:info@el-pixel.com">
            <h4 class="main">info&#64;el-pixel.com</h4>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="social">
    <div class="slogan">
      <h3 class="main" i18n="@@Innovative Tech Expertise You Can Trust">Innovative Tech Expertise You Can Trust</h3>
    </div>

    <div>
      <img src="/assets/icons/social/linkedIn-cube-hover.svg" alt="hover-link" hidden>
      <img src="/assets/icons/social/facebook-cube-hover.svg" alt="hover-fb" hidden>
      <img src="/assets/icons/social/instagram-cube-hover.svg" alt="hover-insta" hidden>
    </div>

    <div class="social-link">
      <a href="https://www.linkedin.com/company/el-pixel/" target="_blank">
        <div class="linkedIn social-cube"></div>
      </a>
      <a href="https://www.facebook.com/elpixel.bel/" target="_blank">
        <div class="facebook social-cube"></div>
      </a>
      <a href="https://www.instagram.com/elpixel_group/" target="_blank">
        <div class="instagram social-cube"></div>
      </a>
    </div>
  </div>
  <div class="rights-policy">
    <div class="copyright">
      <p class="l normal end" i18n="@@© EL PIXEL {{ year }}. All rights reserved">
        © EL PIXEL {{ year | date : "yyyy" }}. All rights reserved
      </p>
    </div>
    <div class="policy">
      <p class="l bold end" routerLink="/privacy-policy" i18n="@@Privacy Policy">Privacy Policy</p>
    </div>
  </div>
</div>
