import { MatIconRegistry } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule()
export class IconsModule {
  constructor(
    private _domSanitizer: DomSanitizer,
    private _matIconRegistry: MatIconRegistry
  ) {
    //technologies-icons
    this._matIconRegistry.addSvgIcon(
      'net',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/technologies/net.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'angular',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/technologies/angular.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'aws',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/technologies/aws.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'azure',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/technologies/azure.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'gcp',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/technologies/gcp.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'java',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/technologies/java.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'python',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/technologies/python.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'react',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/technologies/react.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'vue',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/technologies/vue.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'vue',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/technologies/vue.svg'
      )
    );

    //social-icons
    this._matIconRegistry.addSvgIcon(
      'facebook',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/social/Facebook.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'instagram',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/social/Instagram.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'linkedin',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/technologies/Linkedin.svg'
      )
    );

    //simple-icons
    this._matIconRegistry.addSvgIcon(
      'arrow-up',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/arrow-up.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'arrow-right',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/arrow-right.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'arrow-up-right',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/arrow-up-right.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'arrow-up-right-bold',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/arrow-up-right-bold.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'attach',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/attach.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'business-analysis',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/business-analysis.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'check',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/check.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'design',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/design.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'machine-learning',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/machine-learning.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'menu',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/menu.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'mobile-app',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/mobile-app-dev.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'software-dev',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/software-dev.svg'
      )
    );

    //logo-icons
    this._matIconRegistry.addSvgIcon(
      'logo-black',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/logo-black.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'logo-white',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/logo-white.svg'
      )
    );

    //numbers
    this._matIconRegistry.addSvgIcon(
      'number-1',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/numbers/number-1.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'number-2',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/numbers/number-2.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'number-3',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/numbers/number-3.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'number-4',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/numbers/number-4.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'number-5',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/numbers/number-5.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'number-6',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/numbers/number-6.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'number-7',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/numbers/number-7.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'number-8',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/numbers/number-8.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'number-9',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/numbers/number-9.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'number-10',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/numbers/number-10.svg'
      )
    );

    //navbar icons
    this._matIconRegistry.addSvgIcon(
      'navbar-open',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/open-navbar.svg'
      )
    );
    this._matIconRegistry.addSvgIcon(
      'navbar-close',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/close-navbar.svg'
      )
    );

    // logo plug
    this._matIconRegistry.addSvgIcon(
      'logo-plug',
      this._domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/logo-plug.svg'
      )
    );
  }
}
