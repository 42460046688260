import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Industry } from '@interfaces/industry.interface';
import {
  DepartmentsResponse,
  IndustriesResponse,
  LanguagesResponse,
  ProjectsTechnologiesResponse,
  ServicesResponse,
  TechnologiesResponse,
} from '@interfaces/response.interface';
import { Service } from '@interfaces/service.interface';
import { ProjectTechnologies } from '@interfaces/technologies.interface';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ArgumentsService {
  constructor(private http: HttpClient) {}

  public lang: string | null = localStorage.getItem('lang');

  public getLanguages() {
    const body = `query Query {
      languages {
        uuid
        name
      }
    }`;

    return this.http
      .post<LanguagesResponse>(environment.pixelUrl, {
        query: JSON.parse(JSON.stringify(body)),
        variables: { language: [this.lang] },
        operationName: 'Query',
      })
      .pipe(map(res => res.data.languages));
  }

  public getDepartments() {
    const body = `query Query {
      departments {
        uuid
        name
      }
    }`;

    return this.http
      .post<DepartmentsResponse>(environment.pixelUrl, {
        query: JSON.parse(JSON.stringify(body)),
        variables: { language: [this.lang] },
        operationName: 'Query',
      })
      .pipe(map(res => res.data.departments));
  }

  public getTechnologies() {
    const body = `query Query {
      technologies {
        uuid
        name
      }
    }`;

    return this.http
      .post<TechnologiesResponse>(environment.pixelUrl, {
        query: JSON.parse(JSON.stringify(body)),
        variables: { language: [this.lang] },
        operationName: 'Query',
      })
      .pipe(map(res => res.data.technologies));
  }

  public getAllIndustries(): Observable<Industry[]> {
    const body = `query Query {
                industries {
                  industry_uuid
                  lang_uuid
                  name
                  uuid
                  major_industry_name
                }
              }`;

    return this.http
      .post<IndustriesResponse>(environment.pixelUrl, {
        query: JSON.parse(JSON.stringify(body)),
        variables: { language: [this.lang] },
        operationName: 'Query',
      })
      .pipe(map(res => res.data.industries));
  }

  public getAllTechnologies(): Observable<ProjectTechnologies[]> {
    const body = `query Query {
              technologies {
                name
                uuid
              }
            }`;

    return this.http
      .post<ProjectsTechnologiesResponse>(environment.pixelUrl, {
        query: JSON.parse(JSON.stringify(body)),
        variables: { language: [this.lang] },
        operationName: 'Query',
      })
      .pipe(map(res => res.data.technologies));
  }

  public getAllServices(): Observable<Service[]> {
    const body = `query Query {
              services {
                lang_uuid
                name
                service_uuid
                uuid
              }
            }`;

    return this.http
      .post<ServicesResponse>(environment.pixelUrl, {
        query: JSON.parse(JSON.stringify(body)),
        variables: { language: [this.lang] },
        operationName: 'Query',
      })
      .pipe(map(res => res.data.services));
  }
}
