import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReviewsResponse } from '@interfaces/response.interface';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReviewsService {
  constructor(private http: HttpClient) {}

  public getAllReviews() {
    const lang = localStorage.getItem('lang');
    const body = `query Query {
      reviews {
        uuid
        hide
        lang_uuid
        logoUrl
        name
        sort
        text
        language {
          uuid
          name
        }
      }
    }`;

    return this.http
      .post<ReviewsResponse>(environment.pixelUrl, {
        query: JSON.parse(JSON.stringify(body)),
        variables: { language: [lang] },
        operationName: 'Query',
      })
      .pipe(map((res) => res.data.reviews));
  }
}
