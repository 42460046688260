<div class="header">
  <div class="header__logo" (click)="redirectTo(['/home'])">
    <app-logo [type]="'black'"></app-logo>
  </div>
  <div class="header__navigation">
    <div class="header__navigation--action" (mouseenter)="openNavbarMenu('technologies')" (mouseleave)="closeNavbarMenu()">
      <button class="button-filer">
        <p
        class="l bold navbar-main"
        [ngClass]="{ active: (activeRoute | async) == '/technologies' }"
        (click)="redirectTo(['/technologies'])"
        i18n="@@Technologies">Technologies</p>
      </button>
      @if(this.navbarMenu() == 'technologies'){
        <div class="navbar technologies">
          <div class="technology">
            <ul class="custom">
              <p
                class="l bold ul-header"
                (click)="redirectTo(['/technologies/domain/frontend'])"
                i18n="@@Frontend development">Frontend development</p>
              <li class="li-navbar" (click)="redirectTo(['/technologies/Angular'])">
                <button class="button-filer">
                  <p class="l bold navbar-main">Angular</p>
                </button>
              </li>
              <li class="li-navbar" (click)="redirectTo(['/technologies/React'])">
                <button class="button-filer">
                  <p class="l bold navbar-main">React</p>
                </button>
              </li>
              <li class="li-navbar" (click)="redirectTo(['/technologies/Vue'])">
                <button class="button-filer">
                  <p class="l bold navbar-main">Vue</p>
                </button>
              </li>
            </ul>
            <ul class="custom">
              <p
                class="l bold ul-header"
                (click)="redirectTo(['/technologies/domain/backend'])"
                i18n="@@Backend development">Backend development</p>
              <li class="li-navbar" (click)="redirectTo(['/technologies/Java'])">
                <button class="button-filer">
                  <p class="l bold navbar-main">Java</p>
                </button>
              </li>
              <li class="li-navbar" (click)="redirectTo(['/technologies/Python'])">
                <button class="button-filer">
                  <p class="l bold navbar-main">Python</p>
                </button>
              </li>
              <li class="li-navbar" (click)="redirectTo(['/technologies/NET'])">
                <button class="button-filer">
                  <p class="l bold navbar-main">.NET</p>
                </button>
              </li>
            </ul>
          </div>
          <div class="technology">
            <ul class="custom">
              <p
                class="l bold ul-header"
                (click)="redirectTo(['/technologies/domain/ml&ai'])"
                i18n="@@Machine Learning & Artificial Intelligence">Machine Learning & Artificial Intelligence</p>
              <li class="li-navbar" (click)="redirectTo(['/technologies/Vision-models'])">
                <button class="button-filer">
                  <p class="l bold navbar-main" i18n="@@Vision models">Vision models</p>
                </button>
              </li>
              <li class="li-navbar" (click)="redirectTo(['/technologies/Diffusion-models'])">
                <button class="button-filer">
                  <p class="l bold navbar-main" i18n="@@Diffusion models">Diffusion models</p>
                </button>
              </li>
              <li class="li-navbar" (click)="redirectTo(['/technologies/Language-models'])">
                <button class="button-filer">
                  <p class="l bold navbar-main" i18n="@@Language models">Language models<br></p>
                </button>
              </li>
            </ul>
            <ul class="custom">
              <p
                class="l bold ul-header"
                (click)="redirectTo(['/technologies/domain/platform-solutions'])"
                i18n="@@Platform solutions">Platform solutions</p>
              <li class="li-navbar" (click)="redirectTo(['/technologies/AWS'])">
                <button class="button-filer">
                  <p class="l bold navbar-main">AWS</p>
                </button>
              </li>
              <li class="li-navbar" (click)="redirectTo(['/technologies/Azure'])">
                <button class="button-filer">
                  <p class="l bold navbar-main">Azure</p>
                </button>
              </li>
              <li class="li-navbar" (click)="redirectTo(['/technologies/GCP'])">
                <button class="button-filer">
                  <p class="l bold navbar-main">GCP</p>
                </button>
              </li>
            </ul>
          </div>
          <div class="action">
            <button class="button-link" (click)="redirectTo(['/technologies'])" i18n="@@All technologies *icon*">All technologies<mat-icon class="small" svgIcon="arrow-up-right-bold"></mat-icon></button>
          </div>
        </div>
      }
    </div>
    <div class="header__navigation--action" (mouseenter)="openNavbarMenu('services')" (mouseleave)="closeNavbarMenu()">
      <p
        class="l bold navbar-main"
        [ngClass]="{ active: (activeRoute | async) == '/services' }"
        (click)="redirectTo(['/services'])"
        i18n="@@Services">Services</p>
      @if(this.navbarMenu() == 'services'){
        <div class="navbar services">
          <ul class="custom">
            <li class="li-navbar" (click)="redirectTo(['/services/service-development'])">
              <button class="button-filer">
                <p class="l bold navbar-main" i18n="@@Software Development">Software Development</p>
              </button>
            </li>
            <li class="li-navbar" (click)="redirectTo(['/services/mobile-app-development'])">
              <button class="button-filer">
                <p class="l bold navbar-main" i18n="@@Mobile App Development">Mobile App Development</p>
              </button>
            </li>
            <li class="li-navbar" (click)="redirectTo(['/services/MA-AII'])">
              <button class="button-filer">
                <p class="l bold navbar-main" i18n="@@Machine Learning & Artificial Intelligence integration">Machine Learning & Artificial Intelligence integration</p>
              </button>
            </li>
            <li class="li-navbar">
              <button class="button-filer">
                <p class="l bold navbar-main" (click)="redirectTo(['/services/UX-UI'])" i18n="@@UX & UI Design">UX & UI Design</p>
              </button>
            </li>
            <li class="li-navbar">
              <button class="button-filer">
                <p class="l bold navbar-main" (click)="redirectTo(['/services/business-analysis'])" i18n="@@Business Analysis">Business Analysis</p>
              </button>
            </li>
          </ul>
          <div class="action">
            <button class="button-link" (click)="redirectTo(['/services'])" i18n="@@All services *icon*">
              All services
              <mat-icon class="small" svgIcon="arrow-up-right-bold"></mat-icon>
            </button>
          </div>
        </div>
      }
    </div>
    <div class="header__navigation--action" (mouseenter)="openNavbarMenu('industries')" (mouseleave)="closeNavbarMenu()">
      <p
        class="l bold navbar-main"
        [ngClass]="{ active: (activeRoute | async) == '/industries' }"
        (click)="redirectTo(['/industries'])"
        i18n="@@Industries">Industries</p>
      @if(this.navbarMenu() == 'industries'){
        <div class="navbar industries">
          <ul class="custom">
            <li class="li-navbar" (click)="redirectTo(['/industries/finance'])">
              <button class="button-filer">
                <p class="l bold navbar-main" i18n="@@Finance & Banking">Finance & Banking</p>
              </button>
            </li>
            <li class="li-navbar" (click)="redirectTo(['/industries/healthcare'])">
              <button class="button-filer">
                <p class="l bold navbar-main" i18n="@@Healthcare & Life Sciences">Healthcare & Life Sciences</p>
              </button>
            </li>
            <li class="li-navbar" (click)="redirectTo(['/industries/ecommerce'])">
              <button class="button-filer">
                <p class="l bold navbar-main" i18n="@@eCommerce & Retail">eCommerce & Retail</p>
              </button>
            </li>
            <li class="li-navbar" (click)="redirectTo(['/industries/innovations'])">
              <button class="button-filer">
                <p class="l bold navbar-main" i18n="@@Hi-Tech & Innovations">Hi-Tech & Innovations</p>
              </button>
            </li>
            <li class="li-navbar" (click)="redirectTo(['/industries/education'])">
              <button class="button-filer">
                <p class="l bold navbar-main" i18n="@@eLearning & Education">eLearning & Education</p>
              </button>
            </li>
            <li class="li-navbar" (click)="redirectTo(['/industries/media'])">
              <button class="button-filer">
                <p class="l bold navbar-main" i18n="@@Media & Entertainment">Media & Entertainment</p>
              </button>
            </li>
            <li class="li-navbar" (click)="redirectTo(['/industries/logistics'])">
              <button class="button-filer">
                <p class="l bold navbar-main" i18n="@@Logistics & Transportation">Logistics & Transportation</p>
              </button>
            </li>
          </ul>
          <div class="action">
            <button class="button-link" (click)="redirectTo(['/industries'])" i18n="@@All industries *icon*">All industries<mat-icon class="small" svgIcon="arrow-up-right-bold"></mat-icon></button>
          </div>
        </div>
      }
    </div>
    <div class="header__navigation--action">
      <p
        class="l bold navbar-main"
        [ngClass]="{ active: (activeRoute | async) == '/projects' }"
        (click)="redirectTo(['/projects'])"
        i18n="@@Projects">Projects</p>
    </div>
    <div
      class="header__navigation--action"
      [ngClass]="{ active: (activeRoute | async) === '/company' }"
      (mouseenter)="openNavbarMenu('company')" (mouseleave)="closeNavbarMenu()"
    >
      <p
        class="l bold navbar-main"
        [ngClass]="{ active: (activeRoute | async) == '/company' }"
        (click)="redirectTo(['/company'])"
        i18n="@@Company">Company</p>
      @if(this.navbarMenu() == 'company'){
        <div class="navbar company">
          <ul class="custom">
            <li class="li-navbar">
              <button class="button-filer" (click)="redirectTo(['/company/career'])">
                <p class="l bold navbar-main" i18n="@@Career">Career</p>
              </button>
            </li>
          </ul>
        </div>
      }
    </div>
    <div class="header__navigation--action">
      <button (click)="redirectTo(['/contact-us'])" class="button-primary m" i18n="@@Contact Us">Contact Us<br></button>
    </div>
  </div>
</div>
