import { Component, signal, WritableSignal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { LogoComponent } from '../logo/logo.component';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { filter, map, Observable } from 'rxjs';
import { enterLeaveAnimation } from './header.animation';

@Component({
  selector: 'app-header-web',
  standalone: true,
  imports: [MatIconModule, LogoComponent, RouterModule, CommonModule],
  templateUrl: './header-web.component.html',
  styleUrl: './header-web.component.scss',
})
export class HeaderWebComponent {
  public activeRoute?: Observable<string | undefined>;
  public navbarMenu: WritableSignal<string> = signal('');

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.activeRoute = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event) => (event as NavigationEnd).url)
    );
  }

  public openNavbarMenu(technology: string) {
    this.navbarMenu.set(technology);
    document.body.style.overflowY = 'hidden';
  }

  public closeNavbarMenu() {
    this.navbarMenu.set('');
    document.body.style.overflowY = 'visible';
  }

  public redirectTo(url: string[]) {
    this.router.navigate(url);
    this.closeNavbarMenu();
  }
}

@Component({
  selector: 'app-header-mobile',
  standalone: true,
  imports: [MatIconModule, LogoComponent, RouterModule],
  templateUrl: './header-mobile.component.html',
  styleUrl: './header-mobile.component.scss',
  animations: [enterLeaveAnimation]
})
export class HeaderMobileComponent {
  public navBarMobile: boolean = false;

  toggleNavBar() {
    this.navBarMobile = !this.navBarMobile;
    const mainView = document.querySelector('.main');
    this.navBarMobile
      ? (((mainView as HTMLElement).style.overflowY = 'hidden'),
        (document.body.style.overflowY = 'hidden'))
      : (((mainView as HTMLElement).style.overflowY = 'visible'),
        (document.body.style.overflowY = 'visible'));
  }
}

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [HeaderMobileComponent, HeaderWebComponent],
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  public windowWidth = window.innerWidth;
}
