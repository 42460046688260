<mat-form-field>
  <mat-select
    [formControl]="selectedControl"
    (selectionChange)="onLanguageChange($event.value)"
  >
    @for (lang of languages; track lang) {
     <mat-option [value]="lang">{{ lang.name }}</mat-option>
  }
  </mat-select>
</mat-form-field>
