<div class="header-mobile">
  <div class="header-logo" routerLink="/home">
    <app-logo [type]="'black'"></app-logo>
  </div>
  <div class="navbar-mobile">
    @if(!navBarMobile){
    <div class="action-navbar" (click)="toggleNavBar()">
      <mat-icon class="navbar navbar-open" svgIcon="navbar-open"></mat-icon>
    </div>
    } @else{
    <div class="action-navbar" (click)="toggleNavBar()">
      <mat-icon class="small navbar-close" svgIcon="navbar-close"></mat-icon>
    </div>
    } @if(navBarMobile){
      <div class="navbar-menu" @animate>
        <p class="l bold nav" routerLink="/technologies" (click)="toggleNavBar()" i18n="@@Technologies">
          Technologies
        </p>
        <p class="l bold nav" routerLink="/services" (click)="toggleNavBar()" i18n="@@Services">
          Services
        </p>
        <p class="l bold nav" routerLink="/industries" (click)="toggleNavBar()" i18n="@@Industries">
          Industries
        </p>
        <p class="l bold nav" routerLink="/projects" (click)="toggleNavBar()" i18n="@@Projects">
          Projects
        </p>
        <p class="l bold nav" routerLink="/company" (click)="toggleNavBar()" i18n="@@Company">
          Company
        </p>
        <div>
          <button routerLink="/contact-us" class="button-primary m nav" (click)="toggleNavBar()" i18n="@@Contact Us">Contact Us<br></button>
        </div>
      </div>
    }
  </div>
</div>
