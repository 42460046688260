import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { PersonsResponse } from '@interfaces/response.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PersonService {
  constructor(private http: HttpClient) {}

  public getAllPersons() {
    const lang = localStorage.getItem('lang');
    const body = `query Query {
      persons {
        uuid
        hide
        department_uuid
        email
        imageUrl
        lang_uuid
        name
        phone
        position
        sort
        language {
          uuid
          name
        }
        department {
          uuid
          name
        }
      }
    }`;

    return this.http
      .post<PersonsResponse>(environment.pixelUrl, {
        query: JSON.parse(JSON.stringify(body)),
        variables: { language: [lang] },
        operationName: 'Query',
      })
      .pipe(
        map((res) =>
          res.data.persons.map((person) => ({
            ...person,
            imageUrl: environment.staticUrl + person.imageUrl,
          }))
        )
      );
  }
}
